@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Prata&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .homecate {
    @apply rounded-[40px] border border-gray-300 px-5 py-1 text-sm text-center;
  }
  .homecate_active {
    @apply bg-gradient-to-r from-[#dda5ff] to-[#64e2ff] text-white text-center;
  }
}

label,
input {
  display: block;
  margin-bottom: 20px;
  border: none;
  outline: none;
  padding: 5px 0;
  font-size: 16px;
}
input {
  padding-left: 10px;
  padding-right: 10px;
}

.react-responsive-modal-modal {
  border-radius: 10px;
}

.react-responsive-modal-modal .react-responsive-modal-closeButton {
  display: none;
}

.shadow-my {
  box-shadow: 0px 0px 22px -5px grey;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.homecate_bug {
  padding: 0;
}
